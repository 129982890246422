import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const ChangePassword = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [verified, setVerified] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(30);
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  useEffect(() => {
    if (cooldown) {
      const timer = setInterval(() => {
        setCooldownTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setCooldown(false);
            return 30;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  }, [cooldown]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handleVerificationCodeChange = (e) => setVerificationCode(e.target.value);
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setIsPasswordMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setIsPasswordMatch(newPassword === e.target.value);
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedCode(code);

    const templateParams = {
      to_email: email,
      verification_code: code,
    };

    emailjs.send('service_6y8rvhf', 'template_9ksy0m2', templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
        setShowModal(true);
        setCodeSent(true);
        setCooldown(true);
      }, (error) => {
        setErrorMessage(t('verification_failed'));
      });
  };

  const handleVerifyCode = () => {
    if (verificationCode === generatedCode) {
      setVerified(true);
      setShowModal(false);
    } else {
      setErrorMessage(t('invalid_code'));
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage(t('password_mismatch'));
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_password`, {
        user_email: email,
        new_password: newPassword
      });
      if (response.data.error === t('email_not_registered')) {
        setErrorMessage(t('email_not_registered'));
        alert(t('email_not_registered'));
        resetState(); // Reset to initial state
      } else {
        alert(t('password_changed'));
        sessionStorage.clear();
        navigate('/login');
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
        alert(error.response.data.error);
        resetState(); // Reset to initial state if there's an error
      } else {
        setErrorMessage(t('password_change_failed'));
      }
    }
  };

  const resetState = () => {
    setEmail('');
    setVerificationCode('');
    setGeneratedCode('');
    setNewPassword('');
    setConfirmPassword('');
    setShowModal(false);
    setErrorMessage('');
    setVerified(false);
    setIsEmailValid(false);
    setIsPasswordMatch(false);
    setCodeSent(false);
    setCooldown(false);
    setCooldownTime(30);
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '600px' }}>
      <h1 className="text-center">{t('change_password')}</h1>
      <div className="bg-white p-5 rounded shadow">
        {!verified ? (
          <>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">{t('email')}：</label>
              <div className="d-flex align-items-center">
                <input
                  type="email"
                  className={`form-control ${email && !isEmailValid ? 'is-invalid' : ''}`}
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {email && (
                  <div className={`ms-2 ${isEmailValid ? 'text-success' : 'text-danger'}`}>
                    {isEmailValid ? '✔' : '✘'}
                  </div>
                )}
              </div>
              {email && !isEmailValid && (
                <div className="invalid-feedback">
                  {t('invalid_email')}
                </div>
              )}
            </div>
            <button className="btn btn-primary" onClick={sendVerificationCode} disabled={cooldown}>
              {cooldown ? `${t('resend_code')} (${cooldownTime}秒)` : t('send_verification_code')}
            </button>
          </>
        ) : (
          <>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">{t('new_password')}：</label>
              <div className="d-flex align-items-center">
                <input
                  type="password"
                  className={`form-control ${newPassword && !isPasswordMatch ? 'is-invalid' : ''}`}
                  id="newPassword"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  required
                />
                {newPassword && (
                  <div className={`ms-2 ${isPasswordMatch ? 'text-success' : 'text-danger'}`}>
                    {isPasswordMatch ? '✔' : '✘'}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">{t('confirm_password')}：</label>
              <div className="d-flex align-items-center">
                <input
                  type="password"
                  className={`form-control ${confirmPassword && !isPasswordMatch ? 'is-invalid' : ''}`}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                {confirmPassword && (
                  <div className={`ms-2 ${isPasswordMatch ? 'text-success' : 'text-danger'}`}>
                    {isPasswordMatch ? '✔' : '✘'}
                  </div>
                )}
              </div>
            </div>
            {confirmPassword && !isPasswordMatch && (
              <div className="invalid-feedback">
                {t('password_mismatch')}
              </div>
            )}
            <button className="btn btn-primary" onClick={handleChangePassword}>{t('change_password')}</button>
          </>
        )}
        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('enter_verification_code')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('verification_prompt')}</p>
          <input
            type="text"
            className="form-control"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>{t('cancel')}</Button>
          <Button variant="primary" onClick={handleVerifyCode}>{t('verify')}</Button>
          <Button variant="secondary" onClick={sendVerificationCode} disabled={cooldown}>
            {cooldown ? `${t('resend_code')} (${cooldownTime}秒)` : t('resend_code')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChangePassword;
