import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TextProcessing from '../components/TextProcessing';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const AIDetector = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const defaultService = location.state?.defaultService || '';
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    if (!userId) {
      setShowLoginModal(true);
    }
  }, []);

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  return (
    <div className="container mt-5">
      <div className="bg-white p-5 rounded shadow">
        <TextProcessing defaultService={defaultService} />
      </div>
      <Modal show={showLoginModal} onHide={handleHomeRedirect} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('login_required')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('login_prompt')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AIDetector;
