import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorMessage(t('fill_all_fields')); // 替换硬编码文本
      return;
    }

    const data = {
      user_email: email,
      password: password
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, data);
      const result = response.data;

      console.log(t('login_response'), result);

      if (result.message === "login success") {
        sessionStorage.setItem('userId', result.user_id);
        sessionStorage.setItem('loggedIn', true);

        const userInfoResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user_info`, { user_id: result.user_id });
        const userInfo = userInfoResponse.data;

        console.log(t('user_info'), userInfo);

        const balanceResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/balance`, { user_id: result.user_id });
        const balanceInfo = balanceResponse.data;

        console.log(t('balance_info'), balanceInfo);

        sessionStorage.setItem('userName', userInfo.user_name);
        sessionStorage.setItem('userEmail', userInfo.user_email);
        sessionStorage.setItem('balance', balanceInfo.balance);

        alert(t('login_success')); // 替换硬编码文本
        navigate('/'); 
        window.location.reload();
      } else {
        setErrorMessage(t('login_failed')); // 替换硬编码文本
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
        // 如果 error.message 是 "user email is not exist"
        if (error.response.data.error === "user email is not exist") {
          // 使用翻译函数 t() 来翻译特定的错误信息
          setErrorMessage(t('user_email_not_exist'));
        } else {
          // 如果是其他错误信息，保持原样
          setErrorMessage(error.response.data.error);
        }
      } else {
        console.error(t('login_error_console'), error);
        setErrorMessage(t('try_later')); // 替换硬编码文本
      }
    }
    
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="bg-white p-5 rounded shadow">
            <h1 className="text-center">{t('login')}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">{t('email')}：</label>
                <input 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">{t('password')}：</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">{t('login')}</button>
            </form>
            <div className="mt-3 text-center">
              <span>{t('new_user')}? </span>
              <Link to="/register" className="text-primary">{t('click_here_register')}</Link>
            </div>
            <div className="mt-1 text-center">
              <span>{t('forgot_password')}? </span>
              <Link to="/change_password" className="text-primary">{t('click_here_change')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
