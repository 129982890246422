import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const UpdateUser = () => {
  const userId = sessionStorage.getItem('userId');
  const userEmail = sessionStorage.getItem('userEmail');
  const [username, setUsername] = useState(sessionStorage.getItem('userName') || '');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true);
    }
  }, [userId]);

  const handleUsernameChange = (e) => setUsername(e.target.value);

  const handleUpdateUsername = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/replace`, {
        user_id: userId,
        update_type: 'user_name',
        new_value: username
      });
      alert(t('username_update_success'));
      console.log(response);
      sessionStorage.setItem('userName', username);
      const event = new Event('storage');
      window.dispatchEvent(event);
    } catch (error) {
      setErrorMessage(t('username_update_failed'));
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteEmail !== userEmail) {
      setErrorMessage(t('email_mismatch'));
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/delete`, { user_id: userId });
      alert(t('account_delete_success'));
      console.log(response);
      sessionStorage.clear();
      const event = new Event('storage');
      window.dispatchEvent(event);
      navigate('/');
    } catch (error) {
      setErrorMessage(t('account_delete_failed'));
    }
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '600px' }}>
      <h1 className="text-center">{t('update_user_info')}</h1>
      <div className="bg-white p-5 rounded shadow">
        <div className="mb-3">
          <label htmlFor="username" className="form-label">{t('username')}：</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              style={{ maxWidth: '300px' }}
            />
            <button className="btn btn-primary" onClick={handleUpdateUsername}>{t('update')}</button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">{t('email')}：</label>
          <p id="email" className="form-control-plaintext">{userEmail}</p>
        </div>
        <div className="mb-3">
          <button className="btn btn-warning" onClick={() => navigate('/change_password')}>{t('change_password')}</button>
        </div>
        <div className="mb-3">
          <button className="btn btn-danger" onClick={() => setShowDeleteModal(true)}>{t('delete_account')}</button>
        </div>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_delete_account')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('enter_email_to_confirm')}：</p>
          <input
            type="email"
            className="form-control"
            value={deleteEmail || ''}
            onChange={(e) => setDeleteEmail(e.target.value)}
          />
          {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>{t('cancel')}</Button>
          <Button variant="danger" onClick={handleDeleteAccount}>{t('delete_account')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoginModal} onHide={() => navigate('/')}>
        <Modal.Header closeButton>
          <Modal.Title>{t('login_required')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('login_required_message')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => navigate('/')}>{t('back_home')}</Button>
          <Button variant="primary" onClick={() => navigate('/login')}>{t('login')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateUser;
