import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner, Accordion, Card, Button, Modal, Container, Row, Col, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const History = () => {
  const [historyType, setHistoryType] = useState('check_history');
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true);
    } else {
      fetchHistoryData(historyType);
    }
  }, [historyType, userId]);

  const fetchHistoryData = async (type) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/history_spend`, {
        user_id: userId,
        history_type: type
      });
      const sortedData = response.data.sort((a, b) => new Date(b.time) - new Date(a.time));
      setHistoryData(sortedData);
    } catch (error) {
      console.error(t('fetch_error'), error);
    }
    setLoading(false);
  };

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const highlightText = (text, chunks) => {
    if (!chunks || chunks.length === 0) {
      return <span>{text}</span>;
    }
    const parts = text.split(/(\b[^.!?]+[.!?])/).map((part, index) => {
      const chunkIndex = chunks.findIndex(chunk => chunk.includes(part));
      return (
        <span key={index} style={{ color: chunkIndex !== -1 ? 'red' : 'black', fontWeight: chunkIndex !== -1 ? 'bold' : 'normal' }}>
          {part}
        </span>
      );
    });
    return <>{parts}</>;
  };

  const formatTextForDisplay = (text) => {
    if (!text) {
      return '';
    }
    return text.split('\n').map((line) => line.trim()).join('\n');
  };

  const formatCheckHistory = (item, index) => {
    const afterJson = item.after_json || {};
    const data = afterJson.data || {};

    // 复制函数，用于将文本复制到剪贴板
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        alert(t('copied_to_clipboard'));
      }).catch((err) => {
        console.error(t('error_copying'), err);
      });
    };

    return (
      <Accordion key={`check-${item.check_id || index}`} className="my-3">
        <Card>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Container fluid>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <Row className="gy-2">
                      <Col style={{ whiteSpace: 'nowrap' }}><Badge bg="primary" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('check_id')}:</Badge> {item.check_id}</Col>
                      <Col><Badge bg="info" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('label')}:</Badge> {data.Label}</Col>
                      <Col><Badge bg="warning" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('ai_rate')}:</Badge> {data.Probability}%</Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row className="gy-2">
                      <Col><Badge bg="success" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('time')}:</Badge> {new Date(item.time).toLocaleString('zh-CN', { hour12: false })}</Col>
                      <Col><Badge bg="danger" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('spend')}:</Badge> {item.spend_credit} {t('points')}</Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body>
              <Container fluid>
                <Row className="align-items-center">
                  <Col>
                    <h5 className="d-inline-block">{t('original_text')}:</h5>
                  </Col>
                  <Col className="text-end">
                    <Button variant="outline-secondary" size="sm" onClick={() => copyToClipboard(data.original)}>
                      {t('copy')}
                    </Button>
                  </Col>
                </Row>
                <p style={{ whiteSpace: 'pre-wrap' }}>{highlightText(formatTextForDisplay(data.original), data.Chunks)}</p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    );
  };

  const formatRewriteHistory = (item, index) => {
    const afterJson = item.after_json || {};

    // 复制函数，用于将文本复制到剪贴板
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        alert(t('copied_to_clipboard'));
      }).catch((err) => {
        console.error(t('error_copying'), err);
      });
    };

    return (
      <Accordion key={`rewrite-${item.humanized_id || index}`} className="my-3">
        <Card>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Container fluid>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <Row className="gy-2">
                      <Col style={{ whiteSpace: 'nowrap' }}><Badge bg="primary" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('rewrite_id')}:</Badge> {item.humanized_id}</Col>
                      <Col><Badge bg="info" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('mode')}:</Badge> {afterJson.mode}</Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row className="gy-2">
                      <Col><Badge bg="success" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('time')}:</Badge> {new Date(item.time).toLocaleString('zh-CN', { hour12: false })}</Col>
                      <Col><Badge bg="danger" className="px-2 py-1" style={{ fontSize: '1rem', borderRadius: '10' }}>{t('spend')}:</Badge> {item.spend_credit} {t('points')}</Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  <Col>
                    <Row className="align-items-center">
                      <Col>
                        <h5 className="d-inline-block">{t('original_text')}:</h5>
                      </Col>
                      <Col className="text-end">
                        <Button variant="outline-secondary" size="sm" onClick={() => copyToClipboard(item.origin_text)}>
                          {t('copy')}
                        </Button>
                      </Col>
                    </Row>
                    <p style={{ whiteSpace: 'pre-wrap', marginLeft: 0 }}>{formatTextForDisplay(item.origin_text)}</p>
                  </Col>
                  <Col>
                    <Row className="align-items-center">
                      <Col>
                        <h5 className="d-inline-block">{t('rewritten_text')}:</h5>
                      </Col>
                      <Col className="text-end">
                        <Button variant="outline-secondary" size="sm" onClick={() => copyToClipboard(afterJson.text)}>
                          {t('copy')}
                        </Button>
                      </Col>
                    </Row>
                    <p style={{ whiteSpace: 'pre-wrap', marginLeft: 0 }}>{formatTextForDisplay(afterJson.text)}</p>
                  </Col>
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    );
  };

  const renderHistory = () => {
    return historyData.map((item, index) => {
      if (historyType === 'check_history') {
        return formatCheckHistory(item, index);
      } else if (historyType === 'humanized_history') {
        return formatRewriteHistory(item, index);
      }
      return null;
    });
  };

  return (
    <div className="container mt-5 main-content">
      <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '2rem' }}>{t('history_title')}</h1>
      <div className="btn-group mb-4" role="group" aria-label={t('history_type')}>
        <Button
          variant={historyType === 'check_history' ? 'primary' : 'outline-primary'}
          onClick={() => setHistoryType('check_history')}
          style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}
        >
          {t('check_history')}
        </Button>
        <Button
          variant={historyType === 'humanized_history' ? 'primary' : 'outline-primary'}
          onClick={() => setHistoryType('humanized_history')}
          style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}
        >
          {t('rewrite_history')}
        </Button>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </Spinner>
        </div>
      ) : (
        renderHistory()
      )}
      <Modal show={showLoginModal} onHide={handleHomeRedirect}>
        <Modal.Header closeButton>
          <Modal.Title>{t('not_logged_in')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
          <p>{t('login_prompt_history')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default History;
