import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const RewriteResult = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id, original_text, rewritten_text, mode } = location.state || { id: '', original_text: '', rewritten_text: t('no_rewritten_text'), mode: 'N/A' };

  useEffect(() => {
    // 触发navbar更新的逻辑
    const syncBalance = async () => {
      try {
        const userId = sessionStorage.getItem('userId'); // 获取 user_id
        if (userId) {
          // 更新 balance 并触发事件
          const balanceResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/balance`, { user_id: userId });
          const newBalance = await balanceResponse.data.balance;
          sessionStorage.setItem('balance', newBalance.toString());
          window.dispatchEvent(new Event('balanceUpdated'));
        }
      } catch (error) {
        console.error(t('error_updating_balance'), error);
      }
    };

    syncBalance(); // 调用同步 balance 的方法

    try {
    } catch (error) {
      console.error(t('error'), error);
      console.log(t('id'), id);
    }
  }, [id, t]);

  const formatTextForAnimation = (text) => {
    return text.split('\n').map((line) => line.trim()).join('\n');
  };

  const formattedRewrittenText = formatTextForAnimation(rewritten_text);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(rewritten_text).then(() => {
      alert(t('copied_to_clipboard')); // 可选反馈
    }).catch(err => {
      console.error(t('error_copying'), err);
    });
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h3 className="card-title">{t('original_text')}</h3>
              <div style={{ whiteSpace: 'pre-wrap' }}>{original_text}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <h3 className="card-title mb-0">{t('rewritten_text')}</h3>
                <button 
                  className="btn btn-outline-secondary btn-sm ms-2" 
                  onClick={copyToClipboard}
                  style={{ 
                    borderRadius: '50px', 
                    transition: 'background-color 0.3s, color 0.3s', 
                    padding: '0.25rem 0.75rem' 
                  }}
                >
                  {t('copy')}
                </button>
              </div>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <TypeAnimation
                  sequence={[formattedRewrittenText]}
                  wrapper="span"
                  speed={80}
                  style={{ fontSize: '1rem' }}
                  repeat={0}
                />
              </div>
              <p><strong>{t('rewrite_mode')}:</strong> {mode}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <a href="/AI_detector" className="btn btn-primary">{t('back')}</a>
      </div>
    </div>
  );
};

export default RewriteResult;
