import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Testimonials from '../components/static/Testimonials';
import Features from '../components/static/Features';
import { useTranslation } from 'react-i18next';
import GifPlayer from '../components/GifPlayer';  // 导入 GifPlayer 组件

const Home = () => {
  const [userName, setUserName] = useState(sessionStorage.getItem('userName'));
  const [balance, setBalance] = useState(sessionStorage.getItem('balance'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleStorageChange = () => {
      const loggedIn = sessionStorage.getItem('loggedIn');
      if (loggedIn) {
        const storedUserName = sessionStorage.getItem('userName');
        const storedBalance = sessionStorage.getItem('balance');
        setUserName(storedUserName);
        setBalance(storedBalance);
      } else {
        setUserName(null);
        setBalance(null);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleDetectionClick = () => {
    navigate('/AI_detector', { state: { defaultService: 'Detection' } });
  };

  const handleRewriteClick = () => {
    navigate('/AI_detector', { state: { defaultService: 'Rewrite' } });
  };

  const handleRegisterClick = () => {
    const loggedIn = sessionStorage.getItem('loggedIn');
    if (!loggedIn) {
        navigate('/Register', { state: { defaultService: 'Detection' } });
    } else {
        navigate('/AI_detector', { state: { defaultService: 'Detection' } });
    }
};

  const handleEarnCreditsClick = () => {
    navigate('/Earn_Credits', { state: { defaultService: 'Detection' } });
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="bg-white p-5 rounded shadow">
            <h1 className="display-4 text-success text-center">{t('welcome_to_nlphraser')}</h1>
            {userName && (
              <p className="text-center">{t('welcome_back_user', { userName, balance })}</p>
            )}
            <p className="lead mt-4 text-center">
              {t('nlphraser_description')}
            </p>
            <hr className="my-4" />
            <p className="text-center">{t('welcome_new_user')} </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
               <button className="btn btn-outline-success btn-lg me-2" onClick={handleRegisterClick}>{t('free_trial')}</button> 
              <button className="btn btn-outline-secondary btn-lg" onClick={handleEarnCreditsClick}>{t('share_now')}</button>
            </div>
            <hr className="my-4" />
            <p className="text-center">
              {t('select_service')}
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
               <button className="btn btn-outline-success btn-lg me-2" onClick={handleDetectionClick}>{t('nlp_detection')}</button> 
              <button className="btn btn-outline-secondary btn-lg" onClick={handleRewriteClick}>{t('rewrite_content')}</button>
            </div>
          </div>
        </div>
      </div>
      
      {/* 显示 GifPlayer 组件 */}
      

      <div className="row justify-content-center mt-5">
        <div className="col-md-10">
          <div className="bg-light p-5 rounded shadow">
            <h2 className="card-title">{t('about_nlphraser')}</h2>
            <p className="card-text">
              {t('about_nlphraser_text1')}
            </p>
            <p className="card-text">
              {t('about_nlphraser_text2')}
            </p>
            <p className="card-text">
              {t('about_nlphraser_text3')}
            </p>
          </div>
        </div>
      </div>
<div className="row justify-content-center mt-5">
        <div className="col-md-10">
          <GifPlayer />  {/* GifPlayer 组件显示在这里 */}
        </div>
      </div>
      {/* Features 和 Testimonials 区域 */}
      <Features />
      <Testimonials />
    </div>
  );
};

export default Home;
