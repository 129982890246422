import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(30);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const form = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  useEffect(() => {
    if (cooldown) {
      const timer = setInterval(() => {
        setCooldownTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setCooldown(false);
            return 30;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  }, [cooldown]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateUsername = (username) => {
    return !/^\d+$/.test(username);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const match = e.target.value === confirmPassword;
    setIsPasswordMatch(match);
    
    // 如果密码匹配，清除错误信息
    if (match) {
      setErrorMessage('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    const match = password === e.target.value;
    setIsPasswordMatch(match);
    
    // 如果密码匹配，清除错误信息
    if (match) {
      setErrorMessage('');
    }
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedCode(code);

    const templateParams = {
      to_email: email,
      verification_code: code,
    };

    emailjs.send('service_6y8rvhf', 'template_9ksy0m2', templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
        setCodeSent(true);
        setMessage(t('verification_code_sent'));
        setCooldown(true);
      }, (error) => {
        setMessage(t('verification_code_failed'));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isEmailValid) {
      setErrorMessage(t('invalid_email'));
      return;
    }
  
    if (!isPasswordMatch) {
      setErrorMessage(t('password_mismatch'));
      return;
    }
  
    if (!username || !email || !password || !confirmPassword) {
      setErrorMessage(t('fill_all_fields'));
      return;
    }
  
    if (!validateUsername(username)) {
      setErrorMessage(t('username_not_digits'));
      return;
    }
  
    // 清除错误消息
    setErrorMessage('');
    
    sendVerificationCode(e);
    setShowModal(true);
  };

  const handleVerifyCode = () => {
    if (verificationCode === generatedCode) {
      setShowModal(false);
      submitRegistration();
    } else {
      setErrorMessage(t('invalid_code'));
    }
  };

  const submitRegistration = async () => {
    const data = {
      user_name: username,
      user_email: email,
      password: password
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, data);
      const result = response.data;

      if (result.message === "register success") {
        alert(t('register_success'));
        navigate('/login');
      } else {
        setErrorMessage(t('register_failed'));
      }
    } catch (error) {
      setErrorMessage(t('try_later'));
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="bg-white p-5 rounded shadow">
            <h1 className="text-center">{t('register')}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <form ref={form} className="needs-validation" noValidate onSubmit={handleSubmit}>
              
              {/* 用户名输入 */}
              <div className="mb-3">
                <label htmlFor="username" className="form-label">{t('username')}：</label>
                <div className="d-flex align-items-center">
                  <input 
                    type="text" 
                    className={`form-control ${username && !validateUsername(username) ? 'is-invalid' : ''}`} 
                    id="username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                  {username && (
                    <div className={`ms-2 ${validateUsername(username) ? 'text-success' : 'text-danger'}`}>
                      {validateUsername(username) ? '✔' : '✘'}
                    </div>
                  )}
                </div>
                {username && !validateUsername(username) && (
                  <div className="invalid-feedback">
                    {t('username_not_digits')}
                  </div>
                )}
              </div>

              {/* 邮箱输入 */}
              <div className="mb-3">
                <label htmlFor="email" className="form-label">{t('email')}：</label>
                <div className="d-flex align-items-center">
                  <input 
                    type="email" 
                    className={`form-control ${email && !isEmailValid ? 'is-invalid' : ''}`} 
                    id="email" 
                    value={email} 
                    onChange={handleEmailChange} 
                    required 
                  />
                  {email && (
                    <div className={`ms-2 ${isEmailValid ? 'text-success' : 'text-danger'}`}>
                      {isEmailValid ? '✔' : '✘'}
                    </div>
                  )}
                </div>
                {email && !isEmailValid && (
                  <div className="invalid-feedback">
                    {t('invalid_email')}
                  </div>
                )}
              </div>

              {/* 密码输入 */}
              <div className="mb-3">
                <label htmlFor="password" className="form-label">{t('password')}：</label>
                <div className="d-flex align-items-center">
                  <input 
                    type="password" 
                    className={`form-control ${password && !isPasswordMatch ? 'is-invalid' : ''}`} 
                    id="password" 
                    value={password} 
                    onChange={handlePasswordChange} 
                    required 
                  />
                  {password && (
                    <div className={`ms-2 ${isPasswordMatch ? 'text-success' : 'text-danger'}`}>
                      {isPasswordMatch ? '✔' : '✘'}
                    </div>
                  )}
                </div>
              </div>

              {/* 确认密码输入 */}
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">{t('confirm_password')}：</label>
                <div className="d-flex align-items-center">
                  <input 
                    type="password" 
                    className={`form-control ${confirmPassword && !isPasswordMatch ? 'is-invalid' : ''}`} 
                    id="confirmPassword" 
                    value={confirmPassword} 
                    onChange={handleConfirmPasswordChange} 
                    required 
                  />
                  {confirmPassword && (
                    <div className={`ms-2 ${isPasswordMatch ? 'text-success' : 'text-danger'}`}>
                      {isPasswordMatch ? '✔' : '✘'}
                    </div>
                  )}
                </div>

                {/* 添加密码不匹配的提示 */}
                {confirmPassword && !isPasswordMatch && (
                  <div className="text-danger mt-2">
                    {t('password_mismatch')}
                  </div>
                )}
              </div>
              
              <button type="submit" className="btn btn-primary w-100">{t('register')}</button>
            </form>
          </div>
        </div>
      </div>
      
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('email_verification')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="verificationCode" className="form-label">{t('enter_verification_code')}：</label>
            <input
              type="text"
              className="form-control"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          {message && <div className="alert alert-info">{message}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>{t('close')}</Button>
          <Button variant="primary" onClick={handleVerifyCode}>{t('verify')}</Button>
          <Button variant="secondary" onClick={sendVerificationCode} disabled={cooldown}>
            {cooldown ? `${t('resend_code')} (${cooldownTime}s)` : t('resend_code')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Register;
