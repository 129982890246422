import React from 'react';
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation(); // 使用 i18n 组件的翻译功能

  const primaryFeatures = [
    {
      title: t('precision_rewriting_title'),
      text: t('precision_rewriting_text'),
      icon: '🖋️',
      bgColor: '#e0f7fa'
    },
    {
      title: t('long_text_rewriting_title'),
      text: t('long_text_rewriting_text'),
      icon: '📄',
      bgColor: '#f1f8e9'
    },
    {
      title: t('ai_detection_accuracy_title'),
      text: t('ai_detection_accuracy_text'),
      icon: '🎯',
      bgColor: '#fff3e0'
    },
    {
      title: t('exceptional_performance_title'),
      text: t('exceptional_performance_text'),
      icon: '🌟',
      bgColor: '#fce4ec'
    }
  ];

  const secondaryFeatures = [
    {
      title: t('data_security_title'),
      text: t('data_security_text'),
      icon: '🔐',
      bgColor: '#ffebee'
    },
    {
      title: t('customer_support_title'),
      text: t('customer_support_text'),
      icon: '💬',
      bgColor: '#fffde7'
    },
    {
      title: t('technological_expertise_title'),
      text: t('technological_expertise_text'),
      icon: '💡',
      bgColor: '#e3f2fd'
    },
    {
      title: t('global_standard_title'),
      text: t('global_standard_text'),
      icon: '🔍',
      bgColor: '#f9fbe7'
    }
  ];

  return (
    <>
      <div className="container mt-5 p-5 bg-white rounded">
        <div className="text-center mb-4">
          <h2>{t('ai_text_optimization_tool_title')}</h2>
          <p>{t('ai_text_optimization_tool_description')}</p>
        </div>
        <div className="row">
          {primaryFeatures.map((feature, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: feature.bgColor }}>
                <div className="card-body text-center">
                  <div className="feature-icon mb-3" style={{ fontSize: '2rem' }}>{feature.icon}</div>
                  <h5 className="card-title">{feature.title}</h5>
                  <p className="card-text">{feature.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-5 p-5 bg-white rounded">
        <div className="text-center mb-4">
          <h2>{t('data_security_privacy_title')}</h2>
          <p>{t('data_security_privacy_description')}</p>
        </div>
        <div className="row">
          {secondaryFeatures.map((feature, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: feature.bgColor }}>
                <div className="card-body text-center">
                  <div className="feature-icon mb-3" style={{ fontSize: '2rem' }}>{feature.icon}</div>
                  <h5 className="card-title">{feature.title}</h5>
                  <p className="card-text">{feature.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Features;
