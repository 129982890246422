// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#343a40',
    color: '#ffffff',
    position: 'relative',  // 改为 'relative'，确保footer位于文档流底部
    left: '0',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    zIndex: '1000' // 确保 footer 不遮挡内容
  };

  const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none'
  };

  const hoverStyle = {
    textDecoration: 'underline'
  };

  return (
    <footer style={footerStyle}>
      <div className="container py-2">
        <div className="row">
          <div className="col-md-6 mb-2 mb-md-0">
            <h6 className="text-uppercase">Features</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact_support" style={linkStyle} onMouseEnter={e => e.target.style.textDecoration = hoverStyle.textDecoration} onMouseLeave={e => e.target.style.textDecoration = linkStyle.textDecoration}>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 mb-2 mb-md-0">
            <h6 className="text-uppercase">Resources</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/refund-policy" style={linkStyle} onMouseEnter={e => e.target.style.textDecoration = hoverStyle.textDecoration} onMouseLeave={e => e.target.style.textDecoration = linkStyle.textDecoration}>Refund Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy" style={linkStyle} onMouseEnter={e => e.target.style.textDecoration = hoverStyle.textDecoration} onMouseLeave={e => e.target.style.textDecoration = linkStyle.textDecoration}>Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use" style={linkStyle} onMouseEnter={e => e.target.style.textDecoration = hoverStyle.textDecoration} onMouseLeave={e => e.target.style.textDecoration = linkStyle.textDecoration}>Terms of Use</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#333', padding: '5px 0' }}>
        © 2024 NLPhraser | A state-of-the-art NLP product created by talented engineers.
      </div>
    </footer>
  );
};

export default Footer;
