import React from 'react';

const testimonials = [
  {
    name: 'Emily Johnson',
    title: 'Freelance Writer',
    text: 'NLPhraser has significantly improved the quality of my writing by reducing the AIGC content. The detection tool is fast, reliable, and ensures my data stays private!',
    img: 'https://randomuser.me/api/portraits/women/22.jpg'
  },
  {
    name: 'Michael Smith',
    title: 'SEO Specialist',
    text: 'Thanks to NLPhraser, I can now ensure that all content is human-generated. The security measures in place make me confident that my client data is never leaked.',
    img: 'https://randomuser.me/api/portraits/men/33.jpg'
  },
  {
    name: 'Sarah Williams',
    title: 'Graduate Student',
    text: 'NLPhraser has been a lifesaver in avoiding unnatural content. Plus, their strict privacy policies ensure that my academic papers remain confidential.',
    img: 'https://randomuser.me/api/portraits/women/34.jpg'
  },
  {
    name: 'David Brown',
    title: 'Tech Blogger',
    text: 'I use NLPhraser daily to ensure that my articles are 100% human-written. I trust the platform completely because of its strong focus on data security and privacy protection.',
    img: 'https://randomuser.me/api/portraits/men/45.jpg'
  },
  {
    name: 'Jessica Davis',
    title: 'Data Analyst',
    text: 'The NLP detection feature of NLPhraser is outstanding, and the platform’s commitment to privacy gives me peace of mind knowing my reports and data remain secure.',
    img: 'https://randomuser.me/api/portraits/women/50.jpg'
  },
  {
    name: 'John Miller',
    title: 'Novelist',
    text: 'NLPhraser’s tools have simplified my writing process while ensuring the security of my manuscripts. Their no-data-retention policy ensures nothing gets stored.',
    img: 'https://randomuser.me/api/portraits/men/51.jpg'
  }
];

const Testimonials = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center">User Reviews</h2>
      <div className="row">
        {testimonials.map((testimonial, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card h-100 text-center">
              <img src={testimonial.img} className="card-img-top" alt={testimonial.name} style={{ borderRadius: '50%', width: '100px', height: '100px', margin: '20px auto 10px auto' }} />
              <div className="card-body">
                <h5 className="card-title">{testimonial.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{testimonial.title}</h6>
                <p className="card-text">{testimonial.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
