import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Badge, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // 引入i18n

const EarnCredits = () => {
  const { t } = useTranslation(); // 使用i18n的钩子函数
  const [referCode, setReferCode] = useState(''); // 动态的推广码
  const [referHistory, setReferHistory] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0); // 新增：用于存储总积分
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(''); // 状态保存复制成功信息
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true); // 如果没有登录，显示登录弹窗
    } else {
      fetchReferCodeAndHistory(); // 如果已登录，获取推广码和记录
    }
  }, [userId]);

  // 获取推广码和历史记录
  const fetchReferCodeAndHistory = async () => {
    setLoading(true);
    try {
      // 获取推广码
      const referCodeResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get_refer_code`, { user_id: userId });
      setReferCode(referCodeResponse.data.refer_code); // 设置推广码

      // 获取推广记录
      const referralHistoryResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get_refer_history`, { user_id: userId });
      const referHistoryData = referralHistoryResponse.data;
      setReferHistory(referHistoryData.sort((a, b) => new Date(b.refer_time) - new Date(a.refer_time)));

      calculateTotalCredits(referHistoryData); // 计算获得的总积分
    } catch (error) {
      console.error('Error fetching refer code and history:', error);
    }
    setLoading(false);
  };

  const calculateTotalCredits = (history) => {
    const total = history.reduce((acc, item) => acc + parseFloat(item.recharge_credit || 0), 0)  / 4;
    setTotalCredits(total);
  };

  // 复制推广码到剪贴板
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referCode).then(() => {
      setCopySuccess(t('copy_success')); // 使用翻译
      setTimeout(() => setCopySuccess(''), 2000); // 2 秒后清除提示
    }).catch(err => {
      console.error('Failed to copy refer code: ', err);
    });
  };

  // 登录重定向逻辑
  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  // 渲染推广历史记录
  const renderReferHistory = () => {
    if (referHistory.length === 0) {
      return (
        <div className="bg-white p-4 rounded" style={{ textAlign: 'center', border: '1px solid #ddd' }}>
          <p>{t('no_history')}</p> {/* 使用翻译 */}
        </div>
      );
    }

    return (
      <div className="bg-white p-4 rounded" style={{ border: '1px solid #ddd' }}>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px 5px 0 0', textAlign: 'center' }}>
          <h4 className="mb-0">{t('refer_history')}</h4> {/* 使用翻译 */}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            width: '80%',
            height: '1px',
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
            margin: '10px 0'
          }}></div>
        </div>

        <Container fluid>
          {referHistory.map((item, index) => (
            <div key={`refer-${index}`}>
              <Row className="mb-3">
                <Col><Badge bg="info" className="px-2 py-1">{t('from_user')}:</Badge> {item.refer_from_user_name}</Col>
                <Col><Badge bg="success" className="px-2 py-1">{t('to_user')}:</Badge> {item.refer_to_user_name}</Col>
                <Col><Badge bg="warning" className="px-2 py-1">{t('refer_time')}:</Badge> {new Date(item.refer_time).toLocaleString('zh-CN', { hour12: false })}</Col>
                <Col><Badge bg="danger" className="px-2 py-1">{t('recharge_credit')}:</Badge> {item.recharge_credit}</Col>
              </Row>

              {index < referHistory.length - 1 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{
                    width: '80%',
                    height: '1px',
                    background: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
                    margin: '10px 0'
                  }}></div>
                </div>
              )}
            </div>
          ))}
        </Container>
      </div>
    );
  };

  const styles = {
    container: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: '530',
    },
    totalCredits: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '2rem' }}>
        {t('earn_credits_title')}
      </h1>

      <div style={{ ...styles.container, flexDirection: 'column', alignItems: 'center' }}>
        <span style={styles.totalCredits}>{t('total_credits')}: {totalCredits}</span>

        <p style={styles.rewardText}>{t('reward_text')}</p> {/* 使用翻译 */}

        <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ ...styles.referCode, marginRight: '10px' }}>{t('user_refer_code')}{referCode}</span>
        
        <button
          className={`btn btn-outline-success btn-lg`}
          style={styles.button}
          onClick={copyToClipboard}
        >
          {t('copy_code')}
        </button>
        </div>
      </div>

      {copySuccess && <p style={{ color: 'green', textAlign: 'center' }}>{copySuccess}</p>}

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </Spinner>
        </div>
      ) : (
        <div>{renderReferHistory()}</div>
      )}

      <Modal show={showLoginModal} onHide={handleHomeRedirect} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('not_logged_in')}</Modal.Title> {/* 使用翻译 */}
        </Modal.Header>
        <Modal.Body>
          <p>{t('login_message')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_to_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('log_in')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EarnCredits;
