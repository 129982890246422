import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PaymentModal from '../components/PaymentModal';
import './css/Recharge.css';
import { useTranslation } from 'react-i18next';

const currencySymbols = {
  CNY: '￥',
  USD: '$',
  CAD: 'C$',
};

const Recharge = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState('CNY'); // 默认货币为人民币
  const [loading, setLoading] = useState(true); // 加载状态
  const [packages, setPackages] = useState([]); // 动态存储套餐信息
  const [showLoginModal, setShowLoginModal] = useState(false);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const { t } = useTranslation();

  // 初始渲染时调用 API 获取套餐信息
  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true);
    } else {
      fetchPackages();
    }
  }, [userId, currency]);

  // 从 API 获取动态套餐信息
  const fetchPackages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment_rate`);
      const data = await response.json();
  
      // 在这里打印 API 返回的数据
      console.log('API 返回的数据:', data);
  
      setPackages(data[currency]); // 更新套餐信息
    } catch (error) {
      console.error('获取套餐定价失败:', error);
    }
    setLoading(false);
  };
  

  const handlePackageSelect = (pkg) => {
    setSelectedPackage({ ...pkg, currency });
    setShowModal(true);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  return (
    <div className="container recharge-container bg-white p-4 rounded shadow" style={{ marginTop: '60px' }}>
      <h1 className="text-center">{t('recharge')}</h1>
      <p className="text-center">{t('recharge_description')}</p>

      {/* 货币选择器与支付方式图标部分 */}
      <div className="currency-selector text-center mb-3 d-flex align-items-center justify-content-center">
        <div className="payment-icons d-flex justify-content-center">
          <i className="bi bi-credit-card mx-2" title={t('credit_card')}></i>
          <i className="bi bi-paypal mx-2" title={t('paypal')}></i>
          <i className="bi bi-apple mx-2" title={t('apple_pay')}></i>
          <i className="bi bi-google mx-2" title={t('google_pay')}></i>
          <i className="bi bi-wallet2 mx-2" title={t('alipay')}></i>
          <i className="bi bi-wechat mx-2" title={t('wechat_pay')}></i>
        </div>
        <div className="text-center ms-3">
          <label htmlFor="currencySelect" className="form-label">{t('select_currency')}</label>
          <select
            id="currencySelect"
            value={currency}
            onChange={handleCurrencyChange}
            className="form-select form-select-lg"
            style={{ maxWidth: '300px' }}
          >
            <option value="CNY">{t('currency_cny')}</option>
            <option value="USD">{t('currency_usd')}</option>
            <option value="CAD">{t('currency_cad')}</option>
          </select>
        </div>
      </div>

      {loading ? (
        // 显示加载条
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        // 加载完成后展示套餐
        <Row className="justify-content-center">
          {packages.map((pkg, index) => (
            <Col key={index} xs={12} sm={6} md={4} className="mb-4">
              <Card className="package-card">
                <Card.Body>
                  {/* 显示套餐名称 */}
                  <Card.Title>{t(`package_${pkg.name.toLowerCase().replace(/\s+/g, '_')}`)}</Card.Title>

                  <Card.Text className="price">
                    {currencySymbols[currency]}{(pkg.price / 100).toFixed(2)}
                  </Card.Text>
                  <Card.Text className="credits">
                    {pkg.credits} NLPhraser {t('credits')}
                  </Card.Text>
                  <Button variant="success" onClick={() => handlePackageSelect(pkg)}>
                    {t('select_package')}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {selectedPackage && (
        <PaymentModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedPackage={selectedPackage}
          currency={currency} // 将 currency 传递给 PaymentModal
        />
      )}

      {/* 登录弹窗 */}
      <Modal show={showLoginModal} onHide={handleHomeRedirect}>
        <Modal.Header closeButton>
          <Modal.Title>{t('not_logged_in')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
          <p>{t('login_prompt_history')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Recharge;
